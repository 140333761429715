/* eslint-disable prefer-destructuring */
import React, { useContext, useState, useEffect, useLayoutEffect } from 'react'
import cx from 'clsx'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { __RouterContext } from 'react-router'
import { Link } from 'react-router-dom'
import ROUTES, {
  EXTERNAL_LINKS,
  ROUTES_HAVE_SIDEBAR,
  AUTH_ROUTES
} from 'routes/routes'
import APIs from 'api'
import storage from 'libs/storage'
import SnackManager from 'libs/snack'
import { AUTH_TOKEN } from 'constants/app'
import { LogoutIcon } from 'components/Svg'
import { useDispatch, useSelector } from 'react-redux'
import { setShowModalTimeEntry, setunhandledEdit } from 'store/actions/timeEntry'
import { setRoute } from 'store/actions/app'
import checkPermission from 'libs/permission'
import { useStore } from 'hooks'
import useGa from 'hooks/useGa'
import useTranslate from 'hooks/useTranslate'
import MenuLogo from '../MenuLogo/MenuLogo'
import SIDEBAR_ROUTES from './routes'
import s from './SideBar.module.scss'

const minHeightOfSidebar = 824

function SideBar() {
  const { state: globalState } = useStore('GlobalContext')
  const { state: authState } = useStore('AuthContext')
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false)
  const unhandledEditTimeEntry = useSelector(state => state.timeEntry.unhandledEdit)
  const members = useSelector(state => state.settings.members)
  const adminMembers = useSelector(state => state.subscription.adminMembers)
  const dispatch = useDispatch()
  const { history, location } = useContext(__RouterContext)
  const [isOpen, setIsOpen] = useState(true)
  const [isMiniSize, setIsMiniSize] = useState(false)
  const [companyInfo, setCompanyInfo] = useState()
  const ga = useGa('SideBar')
  const t = useTranslate('general.sideBar')
  const appRoutes = [...Object.values(ROUTES), ...Object.values(AUTH_ROUTES)]

  const currentUrl = location.pathname
  const userId = currentUrl.match(/\/(\d+)$/)?.[1]
  const forbiddenAdminUrls = [
    ROUTES.SUBSCRIPTION,
    ROUTES.SUBSCRIPTION_BILLING,
    ROUTES.SUBSCRIPTION_INVOICES,
    ROUTES.ONBOARDING
  ]

  const regularUserPermissibleUrls = [
    ROUTES.BASE,
    ROUTES.HOME_PATH,
    ROUTES.PLANNING,
    ROUTES.ASSISTANT,
    ROUTES.TIME_ENTRY,
    ROUTES.DASHBOARD,
    ROUTES.PROFILE
  ]

  const AutUrls = Object.values(AUTH_ROUTES)

  const isMatchInAppUrls = appRoutes.some(pattern => {
    const regexPattern = new RegExp('^' + pattern.replace(/:\w+/g, '\\d+') + '$')
    return regexPattern.test(currentUrl)
  })

  function getUserRole(user) {
    if (user.roles?.some(role => role.name === 'Owner')) {
      return 'owner'
    }

    if (
      user.roles?.some(role => role.name === 'Admin') &&
      adminMembers?.some(am => am.email === user.email)
    ) {
      return 'admin'
    }

    return 'regular'
  }

  const userWithOwnerRole =
    adminMembers?.length > 0 &&
    members?.find(
      user =>
        user?.id === Number(userId) && user.roles?.some(role => role.name === 'Owner')
    )

  useEffect(() => {
    const userRole = getUserRole(authState.info)
    const isUserActive =
      authState.info?.active === 1 && authState.info?.email_verified_at?.length > 0

    const adminForbidden =
      userWithOwnerRole !== undefined && Object.keys(userWithOwnerRole).length > 0

    const fetchCompanyDetails = () => {
      APIs.getCompanyDetails()
        .then(res => setCompanyInfo(res.data))
        .catch(err => {
          SnackManager.error(err.message ?? err)
        })
    }

    if (isUserActive && !companyInfo && userRole !== 'regular') {
      fetchCompanyDetails()
    }
    if (isUserActive) {
      if (userRole === 'owner' && companyInfo?.currency === null) {
        history.push(ROUTES.ONBOARDING)
      }

      if (
        userRole === 'owner' &&
        companyInfo !== undefined &&
        companyInfo?.currency !== null &&
        currentUrl === '/onboarding'
      ) {
        history.push(ROUTES.DASHBOARD)
      }

      if (isMatchInAppUrls) {
        const isMatchingRegularUserPath = regularUserPermissibleUrls.some(
          route => route === currentUrl
        )
        const isMatchingAdminPath = forbiddenAdminUrls.some(
          route => route === currentUrl
        )
        const isMatchingAuthUrlPath = AutUrls.some(route => route === currentUrl)
        if (isMatchingAuthUrlPath) {
          history.push(ROUTES.DASHBOARD)
        } else if (userRole === 'regular' && !isMatchingRegularUserPath) {
          history.push(ROUTES.FORBIDDEN)
        } else if (userRole === 'admin' && adminForbidden) {
          history.push(ROUTES.FORBIDDEN)
        } else if (userRole === 'admin' && isMatchingAdminPath) {
          history.push(ROUTES.FORBIDDEN)
        }
      }
    }
  }, [authState.info, adminMembers, members, companyInfo])

  useEffect(() => {
    const isRouteWithSidebar = ROUTES_HAVE_SIDEBAR.includes(location.pathname)
    if (isRouteWithSidebar && !isOpen) setIsOpen(true)

    if (!isRouteWithSidebar && isOpen) setIsOpen(false)
  }, [location.pathname]) // eslint-disable-line

  useLayoutEffect(() => {
    dispatch(setunhandledEdit(false))
    function updateSize() {
      if (window.innerHeight > minHeightOfSidebar && isMiniSize) setIsMiniSize(false)
      if (window.innerHeight < minHeightOfSidebar && !isMiniSize) setIsMiniSize(true)
    }
    window.addEventListener('resize', updateSize)

    updateSize()

    return () => window.removeEventListener('resize', updateSize)
  }, [isMiniSize])

  const handleClick = (type, event) => {
    // Detect if Ctrl or Cmd key is pressed
    const isCtrlOrCmdPressed = event.ctrlKey || event.metaKey

    if (isCtrlOrCmdPressed) {
      // Prevent default action when Ctrl/Cmd is pressed
      event.preventDefault()
      return // Exit function to avoid changing the route in the current tab
    }

    let temp = type
    if (typeof temp === 'object') {
      temp = type[0]
    }

    ga('Change Page', temp)
    if (unhandledEditTimeEntry) {
      dispatch(setShowModalTimeEntry(true))
      dispatch(setRoute(temp))
    } else {
      history.push(temp)
    }
  }

  const handleLogout = () => {
    ga('Logout')
    storage.remove(AUTH_TOKEN)
    storage.remove('root')
    localStorage.clear()
    setTimeout(() => history.push(ROUTES.SIGNIN), 500)
    window.location.reload()
  }

  const activeTab = path => {
    if (typeof path === 'object') {
      const route = path.find(item => item === location.pathname)
      if (route) return true
      return false
    }
    return path === location.pathname
  }

  const toggleSidebarExpasion = boolValue => {
    setIsSidebarExpanded(boolValue)
  }

  return (
    <div
      className={cx(s.container, { [s.open]: isOpen, [s.mini]: isMiniSize })}
      id="sidebar-container"
      onMouseEnter={() => toggleSidebarExpasion(true)}
      onFocus={() => toggleSidebarExpasion(true)}
      onMouseLeave={() => toggleSidebarExpasion(false)}
      onBlur={() => toggleSidebarExpasion(false)}
    >
      <a href={EXTERNAL_LINKS.WEBSITE} target="_blank" rel="noopener noreferrer">
        <div className={s.logoContainer}>
          <div className={s.logo}>
            <MenuLogo expand={isSidebarExpanded} />
          </div>
        </div>
      </a>

      <List className={s.appItems}>
        {SIDEBAR_ROUTES.app
          .filter(item =>
            checkPermission(item.permission, globalState.permissions, {
              projectId: 'ALL',
              teamId: 'ALL'
            })
          )
          .map((item, index) => (
            <Link
              key={item.title}
              to={item.title === 'Planning' ? item.path[0] : item.path}
            >
              <ListItem
                button
                classes={{
                  root: cx(s.listItem, {
                    [s.active]: activeTab(item.path),
                    [s.disabled]: item.disabled
                  })
                }}
                onClick={event => handleClick(item.path, event)}
                data-testid="menu-item"
              >
                <ListItemIcon>
                  <div className={s.icon}>
                    <img src={item.icon} alt={item.title} />
                  </div>
                </ListItemIcon>
                <ListItemText classes={{ root: s.listItemText }} primary={t(index)} />
              </ListItem>
            </Link>
          ))}
      </List>

      <List className={s.userItems}>
        {SIDEBAR_ROUTES.user
          .filter(item => checkPermission(item.permission, globalState.permissions))
          .map(item => (
            <Link to={item.path} key={item.title}>
              <ListItem
                button
                classes={{
                  root: cx(s.listItem, { [s.active]: activeTab(item.path) })
                }}
                onClick={event => handleClick(item.path, event)}
                disabled={item.disabled}
                data-testid="menu-item"
              >
                <ListItemIcon>
                  <div className={s.icon}>
                    <img src={item.icon} alt={item.title} />
                  </div>
                </ListItemIcon>
                <ListItemText
                  classes={{ root: s.listItemText }}
                  primary={t(item.title)}
                />
              </ListItem>
            </Link>
          ))}
        <ListItem
          button
          classes={{ root: s.listItem }}
          onClick={handleLogout}
          data-testid="menu-item"
        >
          <ListItemIcon>
            <div className={s.icon}>
              <LogoutIcon />
            </div>
          </ListItemIcon>
          <ListItemText classes={{ root: s.listItemText }} primary={t(6)} />
        </ListItem>
      </List>
    </div>
  )
}

export default SideBar
